import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { Service, WsEvent, WsMsg, compress, decompress } from '@ztp/shared';
import { useAuthContext } from './AuthContext';
//import { useExponentialBackoff } from '../hook/helper/useExponentialBackoff';
import { useTabActive } from '../hook/events/useTabActive';
import { isProd, isStg } from '../helper/env-helper';

const wsUrl = isProd || isStg ? `ws.${process.env.REACT_APP_SERVER_HOST}` : process.env.REACT_APP_SERVER_HOST;
const protocol = process.env.REACT_APP_HTTPS === 'true' ? 'wss://' : 'ws://';

interface IWsContext {
  subscribe?: <T>(wsEvent: WsEvent, listener: (payload: T) => void) => () => void;
  emit?: (wsEvent: WsEvent, service: Service, compressPayload: boolean, payload?: any) => void;
  ready: boolean;
}

const defaultValue = {
  ready: false,
} as IWsContext;

const WebSocketContext = React.createContext<IWsContext>(defaultValue);
export const useWsContext = () => useContext(WebSocketContext);

export function WebSocketProvider({ children }: any) {
  const { authenticated } = useAuthContext();
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  const { isTabActive } = useTabActive();
  //const { nextDelay, currentDelay } = useExponentialBackoff({ initialDelay: 1, factor: 2, maxDelay: 1000 });

  const channel = useMemo(() => new BroadcastChannel('ws-connection-channel'), []);

  const connectToWS = useCallback(() => {
    const ws = new WebSocket(`${protocol}${wsUrl}`);
    setWebsocket((prev) => {
      if (prev) {
        prev.close();
      }
      return ws;
    });

    ws.onopen = () => {
      console.info('WebSocket connected');
      try {
        channel.postMessage('ws-connected');
      } catch (error) {
        console.error('failed to send ws-connceted on BroadcastChannel');
      }
    };

    ws.onclose = (ev) => {
      console.error(`WebSocket disconnected,code: ${ev.code}, reason: ${ev.reason}`);
      setWebsocket(null);
      try {
        channel.postMessage('ws-disconnected');
      } catch (error) {
        console.error('failed to send ws-connceted on BroadcastChannel');
      }
    };
  }, [channel]);

  useEffect(() => {
    if (authenticated === 'unauthenticated') {
      if (websocket) {
        websocket.close();
        setWebsocket(null);
      }
    }
  }, [authenticated, websocket
    
  ]);

  useEffect(() => {
    if (authenticated === 'authenticated' && !websocket && isTabActive) {
      setTimeout(() => {
        connectToWS();
      }, 1000);
    }

    channel.onmessage = (event) => {
      if (event.data === 'ws-connected') {
        if (websocket) {
          console.warn('close ws, following event ws-connected');
          websocket.close();
        }
      } else if (event.data === 'ws-disconnected' && isTabActive) {
        if (!websocket) {
          console.warn('close ws,following event ws-disconnected and tab is active');
          connectToWS();
        }
      }
    };
  }, [authenticated, websocket, connectToWS, isTabActive, channel]);

  const unsubscribe = useCallback(
    (listener: (message: MessageEvent) => void) => {
      websocket?.removeEventListener('message', listener);
    },
    [websocket]
  );

  const subscribe = useCallback(
    <T,>(wsEvent: WsEvent, listener: (payload: T) => void) => {
      const handler = (message: MessageEvent) => {
        const { event, payload, isCompressed } = JSON.parse(message.data) as WsMsg;

        let data = payload;
        if (isCompressed && payload != undefined) {
          data = decompress(payload);
        }

        console.debug('!!!!!!!!!' + JSON.stringify(data));

        if (event === wsEvent) {
          listener(data as T);
        }
      };
      websocket?.addEventListener('message', handler);

      return () => unsubscribe(handler);
    },
    [websocket, unsubscribe]
  );

  const emit = useCallback(
    (wsEvent: WsEvent, service: Service, compressPayload: boolean, payload: any) => {
      if (websocket && websocket.readyState === WebSocket.OPEN) {
        const msg: WsMsg = {
          service,
          event: wsEvent,
          payload: compressPayload ? compress(payload) : payload,
          isCompressed: compressPayload,
        };

        websocket.send(JSON.stringify(msg));
      } else {
        console.warn('WebSocket is not connected.');
      }
    },
    [websocket]
  );

  return (
    <WebSocketContext.Provider value={websocket ? { subscribe, emit, ready: true } : defaultValue}>
      {children}
    </WebSocketContext.Provider>
  );
}
