import { Box, Button, Divider, Input, Typography } from '@mui/joy';
import React, { useCallback, useState } from 'react';
import { useTokenBalance } from '../../hook/web3/useTokenBalance';
import { NumericFormatProps, useNumericFormat } from 'react-number-format';
import { ethers } from 'ethers';
import { CoinIcon } from './CoinIcon';
import { useChainId } from 'wagmi';
import { NetworkIcon } from './NetworkIcon';
import { useERC20Transfer } from '../../hook/web3/useERC20Transfer';
import { Address } from 'viem';
import { useSnackbar } from '../../context/SnackbarContext';
import { useModal } from '../../context/ModalContext';
import palette from '../../styles/theme';

const Send: React.FC = () => {
  const [recipient, setRecipient] = useState('');
  const [amount, setAmount] = useState<number>(0);
  const [errorAmount, setErrorAmount] = useState<string>('');
  const [errorRecipient, setErrorRecipient] = useState<string>('');
  const chainId = useChainId();
  const { token, value: maxBalance } = useTokenBalance();
  const { transfer } = useERC20Transfer();
  const { showMessage } = useSnackbar();
  const { close } = useModal();

  const props: NumericFormatProps = {
    allowNegative: false,
    thousandSeparator: true,
    valueIsNumericString: true,
    decimalScale: 2,
  };
  const { format, removeFormatting } = useNumericFormat(props);

  function isValidEthereumAddress(address: string) {
    try {
      return !!ethers.getAddress(address);
    } catch (error) {
      return false;
    }
  }

  const handlePaste = useCallback(async () => {
    const address = await navigator.clipboard.readText();
    setRecipient(address);
  }, []);

  const handleSend = () => {
    if (!token) {
      return;
    }

    if (!isValidEthereumAddress(recipient)) {
      setErrorRecipient('Invalid Arbitrum wallet address.');
      return;
    }
    setErrorRecipient('');

    if (amount <= 0) {
      setErrorAmount('Amount must be greater than 0.');
      return;
    }

    if (amount > maxBalance) {
      setErrorAmount('Amount exceeds available balance.');
      return;
    }

    setErrorAmount('');

    transfer(token.address, recipient as Address, amount).then((success) => {
      if (success) {
        showMessage('Successfully Sent', 'success');
      } else {
        showMessage('Failed to send', 'danger');
      }
    });

    close();
  };

  if (!format || !removeFormatting || !token) {
    return <></>;
  }

  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'space-around'} height={'300px'} width={'300px'}>
      <Box>
        <Input
          startDecorator={
            <Box display={'flex'} flexDirection={'row'} margin={'6px'}>
              <NetworkIcon chainId={chainId} />
              <Divider sx={{ marginLeft: '8px' }} orientation="vertical" />
            </Box>
          }
          endDecorator={
            <>
              <Divider sx={{ marginLeft: '4px' }} orientation="vertical" />
              <Button color="neutral" variant="plain" onClick={handlePaste}>
                paste
              </Button>
            </>
          }
          error={!!errorRecipient}
          type="text"
          placeholder="Recipient Address"
          value={recipient}
          onChange={(e) => setRecipient(e.target.value)}
        />
        {errorRecipient && (
          <Typography sx={{ margin: '2px' }} level="body-sm" color="danger">
            {errorRecipient}
          </Typography>
        )}
      </Box>
      <Box>
        <Input
          startDecorator={
            <Box display={'flex'} flexDirection={'row'} margin={'6px 6px 4px 6px'}>
              <CoinIcon name={token.symbol} />
              <Divider sx={{ marginLeft: '8px' }} orientation="vertical" />
            </Box>
          }
          endDecorator={
            <>
              <Divider sx={{ marginLeft: '4px' }} orientation="vertical" />
              <Button color="neutral" variant="plain" onClick={() => setAmount(maxBalance)}>
                max
              </Button>
            </>
          }
          error={!!errorAmount}
          placeholder="Amount"
          value={format(amount.toString())}
          onChange={(e) => {
            setAmount(Number(removeFormatting(e.target.value)));
          }}
        />
        {errorAmount && <Typography color="danger">{errorAmount}</Typography>}
      </Box>

      <Box display={'flex'} flexDirection={'column'}>
        <Button
          size="lg"
          sx={{ color: palette.text.primary }}
          variant="soft"
          color="primary"
          onClick={() => handleSend()}
        >
          Send
        </Button>
        <Typography sx={{ marginTop: '18px', color: palette.text.secondary, textAlign: 'center' }} level="body-sm">
          * Make sure you are sending ZTGM tokens on the Arbitrum chain!
        </Typography>
      </Box>
    </Box>
  );
};

export default Send;
