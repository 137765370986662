import { useCallback, useMemo } from 'react';
import { usePlayerContext } from '../../context/PlayerContext';

export const useChipsFormat = () => {
  const { state } = usePlayerContext();
  const { table } = state;

  const decimals = useMemo(() => {
    const small = table?.type.small || 0;
    if (small < 0.1) {
      return 2;
    }
    if (small < 1) {
      return 1;
    }
    return 0;
  }, [table]);

  const chipsFormat = useCallback(
    (amount: number) => {
      if (table?.type.gameFormat == 'tourney') {
        return `${amount.toFixed(decimals)}`;
      }
      return `${amount.toFixed(decimals)}`;
    },
    [table, decimals]
  );

  const round = useCallback(
    (amount: number) => {
      if (table?.type.gameFormat == 'tourney') {
        return Number(amount.toFixed(decimals));
      }
      return Number(amount.toFixed(decimals));
    },
    [table, decimals]
  );

  return { round, chipsFormat };
};
