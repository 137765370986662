import React from 'react';
import { Box } from '@mui/joy';
import { useSmartAccount } from '../../hook/web3/useSmartAccount';
import { TokenType } from '@ztp/shared';
import { CoinIcon } from './CoinIcon';

interface TokenProps {
  tokenType: TokenType;
  iconSize?: string;
}

export const Token: React.FC<TokenProps> = ({ tokenType }) => {
  const { chainId } = useSmartAccount();

  return (
    <Box display="inline-flex" alignItems="center" gap={0.5}>
      <CoinIcon name={tokenType.symbol} chainId={chainId} />
    </Box>
  );
};
