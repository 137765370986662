import { useCallback } from 'react';
import { ethers } from 'ethers';
import { IERC20Factory } from '@ztp/shared';
import { Address, ContractType } from './type';
import { useContractAddress } from './useContractAddress';
import { sleep } from '../../helper/sleep';
import { useSmartAccount } from './useSmartAccount';

const FINALIZED_DELY = 6000;

export const useContarctAllowance = (contractType: ContractType) => {
  const { address, smartClient } = useSmartAccount();
  const { contractAddress } = useContractAddress(contractType);

  console.log('useContarctAllowance initialized with:', { contractType, address, contractAddress });

  const approve = useCallback(
    async (tokenAddress: Address, amount: number, max: number) => {
      console.debug('approve() called with parameters:', {
        tokenAddress,
        amount,
        max,
        contractAddress,
        userAddress: address,
        smartClientExists: !!smartClient,
      });

      if (amount === 0 || !smartClient) {
        console.debug('Amount is 0 or smartClient is not available. Returning true.');
        return true;
      }

      console.debug('Reading allowance from contract...');
      let allowance;
      try {
        allowance = await smartClient.readContract({
          address: tokenAddress as `0x${string}`,
          abi: IERC20Factory.abi,
          functionName: 'allowance',
          args: [address, contractAddress],
        });

        console.debug('Raw allowance from contract:', allowance?.toString() ?? 'undefined');

        if (allowance) {
          const allowanceParsed = parseFloat(ethers.formatUnits(allowance, 6));
          console.debug('Parsed allowance:', allowanceParsed);
          console.debug('Required amount:', amount);

          if (allowanceParsed >= amount) {
            console.debug(`Allowance (${allowanceParsed}) is already sufficient (>= ${amount}). Returning true.`);
            return true;
          }
        } else {
          console.debug('No allowance found. Proceeding to approval.');
        }
      } catch (err) {
        console.error('Error while reading allowance:', err);
        return false;
      }

      console.debug('Creating ethers interface and encoding approval data...');
      let iface, data;
      try {
        iface = new ethers.Interface(IERC20Factory.abi);
        data = iface.encodeFunctionData('approve', [contractAddress, ethers.parseUnits(max.toString(), 6)]);
      } catch (encodingError) {
        console.error('Error encoding function data:', encodingError);
        return false;
      }

      console.debug('Sending approval transaction...');
      let approvalTx;
      try {
        approvalTx = await smartClient.sendUserOperation({
          uo: {
            target: tokenAddress as `0x${string}`,
            data: data as `0x${string}`,
            value: 0n,
          },
        });

        console.debug('Approval transaction submitted:', approvalTx);
        console.debug(`Waiting ${FINALIZED_DELY}ms for finalization...`);
        await sleep(FINALIZED_DELY);

        console.debug('Approval transaction presumed finalized.');
        return true;
      } catch (error) {
        console.error('Failed to approve. Error:', error);
        return false;
      }
    },
    [contractAddress, address, smartClient]
  );

  return { approve };
};
